<template>
  <div>
    <v-row class="my-3">
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center">
          <h5 class="title font-weight-bold">Types</h5>
          <v-spacer></v-spacer>
          <v-btn 
            @click="$refs.createProduct.show()"
            color="primary" 
            class="input-shadow" 
            small
          >Create New Form</v-btn>
        </div>
      </v-col>
    </v-row>

    <preloader v-if="status.getting" />
    
    <v-row>
      <v-col cols="2">
        <v-card class="box-shadow" outlined>
          <v-card-title class="font-weight-bold subtitle-1">
            Categories
          </v-card-title>
          <v-card-text>
            <v-checkbox
              :input-value="filter.length == categories.length"
              @change="$event ? selectAll() : filter = []"
              hide-details
              label="All"
              dense
            ></v-checkbox>

            <div 
              v-for="category in orderBy(categories, 'name', 'asc')"
              class="d-flex align-center justify-space-between"
              :key="category.id"
            >
              <v-checkbox
                :label="category.name"
                :value="category.id"
                v-model="filter"
                hide-details
                multiple
                dense
              ></v-checkbox>

              <v-menu offset-y>
                <template #activator="{ on }">
                  <v-btn icon small>
                    <v-icon v-on="on" small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="$store.commit('types/setData', category)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!productsCount(category).length" @click="confirmDeleteCategory(category)">
                    <v-list-item-title class="error--text">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div v-if="$store.getters['request/canManage']" class="mt-5">
              <v-form ref="form" @submit.prevent="validateForm()">
                <v-text-field
                  placeholder="Category Name"
                  :rules="[rules.required]"
                  v-model="data.name"
                  hide-details
                  class="mb-2"
                  outlined
                  dense
                ></v-text-field>

                <v-btn 
                  :loading="status.creating"
                  @click="validateForm()" 
                  color="primary" 
                  small 
                  block
                >
                  {{ data.id ? 'Update' : 'Add' }}
                </v-btn>
                
                <v-btn 
                  @click="
                    $store.commit('types/setData', {})
                    $refs.form.resetValidation()
                  " 
                  :disabled="status.creating"
                  v-if="data.id"
                  class="mt-2"
                  small 
                  block
                  text
                >Cancel</v-btn>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10">
        <v-fade-transition
          class="d-flex flex-wrap gap-10 flex-justify-start" 
          v-if="confProducts.length && !status.getting"
          group
        >
          <type-card 
            v-for="type in orderBy(confProducts, 'name', 'asc')"
            @selected="$router.push({ name: 'ProductFormBuilder', params: { id: type.ref.parent.parent.id, product: type.id } })"
            @delete="showDeleteDialog" 
            :key="type.id"
            :type="type" 
            noneRoute
            product
          />
        </v-fade-transition>
      </v-col>
    </v-row>

    <!-- CONFIRM TYPE DELETE -->
    <confirm-delete
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
      :deleting="status.deleting"
      :message="deleteMessage"
      :show="deleteDialog"
    />

    <!-- CONFIRM CATEGORY DELETE -->
    <confirm-delete 
      :message="`Confirm delete category ${toDeleteCategory.name}?`"
      @confirmed="deleteCategoryConfirmed()"
      @cancel="closeDeleteCategory()"
      :deleting="status.deleting"
      :show="deleteCategory"
    />

    <!-- CREATE REQUEST TYPE DIALOG -->
    <CreateProduct ref="createProduct" />
  </div>
</template>

<script>
import rules from '@/rules'
import orderBy from 'lodash/orderBy'
import { mapState, mapActions } from 'vuex'

import TypeCard from './components/TypeCard'
import CreateProduct from './components/CreateProductDialog'

export default {
  metaInfo: {
    title: 'Request Categories'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      orderBy,
      filter: [],
      category: null,
      toDelete: null,
      deleteDialog: false,
      deleteMessage: null,
      toDeleteCategory: {},
      deleteCategory: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.types.data,
      status: state => state.types.status,
      categories: state => state.types.types,
      products: state => state.types.products,
    }),

    confProducts: function () {
      let products = this.products

      if (this.filter) {
        products = products.filter(product => {
          return this.filter.includes(product.ref.parent.parent.id)
        })
      }

      return products
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'categories': {
      handler(categories) {
        if (categories.length) {
          this.selectAll()
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    TypeCard,
    CreateProduct,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'getAllProducts',
      'deleteProduct',
      'deleteType',
      'createType',
      'updateType',
      'getTypes',
    ]),

    showDeleteDialog(e) {
      console.log(e.id)
      this.toDelete = e
      this.deleteMessage = `Are you sure you want to delete type "${this.toDelete.name}"?`
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteProduct(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    selectAll() {
      this.filter = []

      this.categories.forEach(category => {
        this.filter.push(category.id)
      })
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        let task 
        
        if (this.data.id) task = Promise.resolve(this.updateType())
        else task = Promise.resolve(this.createType())
        
        task.then(() => {
          this.$store.commit('types/setData', {})
          this.$refs.form.resetValidation()
        })
      }
    },

    confirmDeleteCategory(category) {
      this.toDeleteCategory = category
      this.deleteCategory = true
    },

    closeDeleteCategory() {
      this.deleteCategory = false
    },

    deleteCategoryConfirmed() {
      Promise.resolve(this.deleteType(this.toDeleteCategory))
      .then(() => {
        this.closeDeleteCategory()
      })
    },

    productsCount(category) {
      return this.products.filter(product => {
        return product.ref.parent.parent.id == category.id
      })
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getTypes()
    if (!this.status.firstLoadAllProducts) this.getAllProducts()
  }
}
</script>