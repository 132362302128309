<template>
  <div class="mb-5">
    <div 
        class="mx-auto box-shadow d-flex justify-center align-end rounded-lg transition" 
        :class="{ 'accent' : hover }"
        :style="{ width: '96px', height: '96px' }" 
        color="transparent"
      >
        <v-responsive aspect-ratio="1:1">
          <preloader-circular v-if="getting" width="2" size="30" />
          
          <v-img
            :src="image.image"
            class="mx-auto"
            width="76"
            contain
            v-else
          >
            <template v-slot:placeholder>
              <preloader-circular width="2" size="30" />
            </template>
          </v-img>
        </v-responsive>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    type: Object,
    hover: Boolean,
  },

  data() {
    return {
      getting: false,
    }
  },

  computed: {
    image: function () {
      if (this.type) {
        return this.$store.getters['types/image'](this.type.id) || { image: require('@/assets/placeholder.svg') }
      }
      else return { image: require('@/assets/placeholder.svg') }
    }
  },

  watch: {
    'type': {
      handler(type) {
        if (type) {
          if (!this.$store.getters['types/image'](type.id))
            this.getImageUrl(type)
        }
      },
      immediate: true
    }
  },

  methods: {
    getImageUrl(type) {
      if (type.iconData) {
        this.$store.commit('types/addImage', {
          id: type.id,
          image: type.iconData
        })
      }
      else if (type.icon) {
        this.getting = true
        Promise.resolve(
          this.$store.dispatch('types/getTypeImage', type)
        )
        .then(() => {
          setTimeout(() => {
            this.getting = false
          }, 2000)
        })
      }
    }
  },
}
</script>